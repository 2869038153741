import React, { Component } from "react";
import { Image, Dropdown } from "react-bootstrap";
import { Button } from "antd";
import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillCameraVideoFill,
  BsFillCameraVideoOffFill,
  BsThreeDots,
  BsSpeedometer2,
  BsFillChatRightDotsFill,
} from "react-icons/bs";
import { MdOutlineScreenShare, MdCallEnd } from "react-icons/md";
import { FaHandPaper, FaUserAlt } from "react-icons/fa";
import { HiSquares2X2 } from "react-icons/hi2";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="dropdownbutton"
  >
    <Button
      style={{ margin: "auto 3px" }}
      size="large"
      shape="round"
      icon={<BsThreeDots />}
    />
    {children}
    {/* &#x25bc; */}
  </a>
));

class JitsiWindow extends Component {
  api = {};
  DOMAIN = "conferencing.dialogview.ai";

  constructor(props) {
    super(props);
    this.state = {
      jitsiStarted: true,
      room: this.props.meetingId || "Room",
      userName: this.props.userName || "Test User",
      companyLogoURL: this.props.companyLogoURL,
      isAudioMuted: false,
      isTileView: false,
      isScreenShare: false,
      isVideoMuted: false,
      initialrender: false,
    };
  }

  UNSAFE_componentWillMount() {
    console.log("this.props ==>", this.props);
    let appointment = this.props.appointment ? this.props.appointment : null;
    if (appointment) {
      this.setState({
        room: appointment.meetingId,
      });
    }
  }

  componentDidMount() {
    if (window.JitsiMeetExternalAPI) {
      // if (!document.mozFullScreen && !document.webkitFullScreen) {
      //   if (videoElement.mozRequestFullScreen) {
      //     videoElement.mozRequestFullScreen();
      //   } else {
      //     videoElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      //   }
      // } else {
      //   if (document.mozCancelFullScreen) {
      //     document.mozCancelFullScreen();
      //   } else {
      //     document.webkitCancelFullScreen();
      //   }
      // }
      // if (this.state.initialrender) {
      // document
      //   .getElementById("largeVideoBackgroundContainer")
      //   .requestFullscreen();
      this.startMeet();
      // }
    } else {
      alert("JitsiMeetExternalAPI not loaded");
    }
  }

  startMeet = () => {
    const options = {
      roomName: this.state.room,
      width: "100%",
      height: window.innerHeight - 150,
      configOverwrite: {
        prejoinPageEnabled: false,
        startWithVideoMuted: true,
        startWithAudioMuted: true,
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        disableRemoteMute: true,
        remoteVideoMenu: {
          disableKick: true,
        },
        disableDeepLinking: true,
        desktopSharingFrameRate: {
          min: 30,
          max: 30,
        },
      },
      interfaceConfigOverwrite: {
        // overwrite interface properties
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        MOBILE_APP_PROMO: false,
        TOOLBAR_BUTTONS: [
          "fullscreen",
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          // "embedmeeting",
          "fodeviceselection",
          "hangup",
          // "profile",
          "chat",
          "recording",
          "livestreaming",
          // "etherpad",
          // "sharedvideo",
          // "shareaudio",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          // "invite",
          "feedback",
          // "stats",
          "shortcuts",
          "tileview",
          "select-background",
          "download",
          // "security",
          "whiteboard",
        ],
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        displayName: this.state.userName,
      },
      onload: () => {},
    };
    this.api = new window.JitsiMeetExternalAPI(this.DOMAIN, options);

    this.api.addEventListeners({
      readyToClose: this.handleClose,
      participantLeft: this.handleParticipantLeft,
      participantJoined: this.handleParticipantJoined,
      videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      audioMuteStatusChanged: this.handleMuteStatus,
      videoMuteStatusChanged: this.handleVideoStatus,
    });
  };

  hideLogo = () => {
    document.getElementsByClassName(
      "watermark leftwatermark"
    )[0].style.visibility = "hidden";
  };

  handleClose = () => {
    console.log("handleClose");
  };

  handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft");
    this.setState({ jitsiStarted: false });
    this.api = null;
    this.props.closeModal();
    // return this.props.history.push("/thank-you");
  };

  handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
    // this.hideLogo();
    if (audio.muted) {
    } else {
      // enable noise suppression
      setTimeout(() => {
        this.api.executeCommand("setNoiseSuppressionEnabled", {
          enabled: true, // Enable or disable noise suppression.
        });
      }, 2000);
    }
  };

  handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  // custom events
  executeCommand(command) {
    this.api.executeCommand(command);
    if (command == "hangup") {
      return this.props.history.push("/thank-you");
    }

    if (command == "toggleAudio") {
      this.setState({ isAudioMuted: !this.state.isAudioMuted });
    }

    if (command == "toggleVideo") {
      this.setState({ isVideoMuted: !this.state.isVideoMuted });
    }
    if (command == "toggleTileView") {
      this.setState({ isTileView: !this.state.isTileView });
    }
    if (command == "toggleShareScreen") {
      this.setState({ isScreenShare: !this.state.isScreenShare });
    }
  }

  closeModal = () => {
    this.setState({ jitsiStarted: true });
    this.props.closeModal();
  };

  componentWillUnmount() {
    this.state.initialrender = true;
  }

  render() {
    const {
      isAudioMuted,
      isVideoMuted,
      isTileView,
      isScreenShare,
    } = this.state;

    return (
      <>
        {this.state.jitsiStarted ? (
          <div>
            <header className="nav-bar">
              {/* <p className="item-left heading">Jitsi React</p> */}
              <div className="jitsi_header">
                <div className="company-logo jitsi_header_item jitsi-header_logo">
                  <Image
                    style={{
                      // borderRadius: "75%",
                      width: "auto",
                      height: "10vh",
                      // borderColor: "#333",
                      // boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
                      objectFit: "contain",
                      padding: "5px",
                    }}
                    preview={false}
                    width="auto"
                    height={50}
                    src={
                      this.state.companyLogoURL ||
                      "https://agent.qa.thebanknet.com/agent/api/v1/s3/company/6179183fb5a9093844332b5f/key/221020112703-joon-watermark.png"
                    }
                  />
                </div>
                {/* <div
                  className="jitsi_header_item"
                  style={{ fontWeight: "bold", width: "25%" }}
                > */}
                {/* {localStorage.getItem("company")
                    ? `${localStorage.getItem("company")}`
                    : ""} */}
                {/* </div> */}
                <div
                  className="jitsi_header_item meet_summary"
                  style={{
                    // position: "fixed",
                    width: "95%",
                    textAlign: "center",
                  }}
                >
                  {this.props.meetingDetails.summary || ""}
                </div>
              </div>
            </header>
            <div id="jitsi-iframe"></div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h1>Thank You .....!</h1>

            <div className="save-btn">
              <Button type="primary" onClick={(e) => this.closeModal()}>
                Close
              </Button>
            </div>
          </div>
        )}
        {/* <div class="item-center">
          <span>&nbsp;&nbsp;</span>
          <i
            onClick={() => this.executeCommand("toggleAudio")}
            className={`fas fa-2x grey-color ${
              isAudioMuted ? "fa-microphone-slash" : "fa-microphone"
            }`}
            aria-hidden="true"
            title="Mute / Unmute"
          ></i>
          <i
            onClick={() => this.executeCommand("hangup")}
            className="fas fa-phone-slash fa-2x red-color"
            aria-hidden="true"
            title="Leave"
          ></i>
          <i
            onClick={() => this.executeCommand("toggleVideo")}
            className={`fas fa-2x grey-color ${
              isVideoMuted ? "fa-video-slash" : "fa-video"
            }`}
            aria-hidden="true"
            title="Start / Stop camera"
          ></i>
          <i
            onClick={() => this.executeCommand("toggleShareScreen")}
            className="fas fa-film fa-2x grey-color"
            aria-hidden="true"
            title="Share your screen"
          ></i>
        </div> */}
      </>
    );
  }
}

export default JitsiWindow;
