import React, { useEffect, useState } from "react";
import DialogViewImage from "./assets/images/dv-logo.png";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Button,
  Tooltip,
  notification,
  Layout,
  Flex,
  Menu,
  Avatar,
  Divider,
  List,
  Skeleton,
  Card,
  Image,
  Collapse,
} from "antd";
import { RiMenuUnfoldFill, RiMenuFoldFill } from "react-icons/ri";
import { BsPeopleFill } from "react-icons/bs";
import { MdDataUsage } from "react-icons/md";
import "./agentjitsiconference.css";
import { useParams } from "react-router-dom";
import {
  AGENT_SERVICE,
  AUTHENTICATION_SERVICE,
} from "./environment/environment";
import axios from "axios";
import ConferenceCall from "./assets/images/conference-call.gif";
const { Header, Footer, Sider, Content } = Layout;

const AgentCallWindow = ({ title, children }) => {
  const [state, setState] = useState({
    userName: "",
    meetingId: "",
    startMeet: false,
    openModal: false,
    meetingDetails: {},
    status: "",
    showAlert: false,
    showWaiting: false,
    alertMessage: "",
    alertType: "",
    password: "",
    companyLogoURL: "",
    showPassword: false,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [appointment, setAppointment] = useState({});
  const [callClosed, setCallClosed] = useState(false);
  const [detailCollapsed, setDetailCollapsed] = useState(false);
  const [attendeesEmailList, setAttendeesEmailList] = useState([]);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const urlParams = new URLSearchParams(window.location.search);
  console.log("parameters", urlParams);
  console.log(">>>>>>>>>>>>>>>", params);
  console.log("meetingid", params.id);
  let appointmentId = params.id ? params.id : null;
  let agentId = params.agid ? params.agid : null;
  let userName =
    params.fname && params.lname ? params.fname + " " + params.lname : null;
  let jitsiApi = null;
  const meetingUpdate = async (callStarted, appointmentDetails) => {
    var data = JSON.stringify({
      ...appointmentDetails.data.data,
      callStarted: callStarted,
    });
    console.log(
      "meetingUpdate=",
      callStarted,
      appointmentDetails,
      "body=",
      data
    );

    var urls = `${AGENT_SERVICE}api/v1/Meeting/Update`;
    var config = {
      method: "POST",
      url: urls,
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(config);
  };

  const userStatusChange = async (userId, status) => {
    var urls = `${AUTHENTICATION_SERVICE}api/v1/User/${userId}/status/${status}`;
    var config = {
      method: "PUT",
      url: urls,
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
    };
    return await axios(config);
  };
  const handleClose = (appointmentDetails) => {
    console.log("handleClose");
    updateScheduleStatus(false, appointmentDetails);
    userStatusChange(agentId, "online");
    meetingUpdate(false, appointmentDetails);
    setCallClosed(true);
    // update agent status to online
  };
  const updateScheduleStatus = (callStarted, appointmentDetails) => {
    let body = {
      ...appointmentDetails.data.data,
      callStarted: callStarted,
    };
    console.log(
      "updateScheduleStatus=",
      callStarted,
      appointmentDetails,
      "body=",
      body
    );

    updateConferenceScheduleStatus(body)
      .then((res) => {
        console.log("res => ", res);
      })
      .catch((error) => {});
  };

  const updateConferenceScheduleStatus = async (data) => {
    var urls = `${AGENT_SERVICE}api/v1/appointment/status`;
    var config = {
      method: "post",
      url: urls,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        // 'token': await token(history),
      },
      data: data,
    };
    return await axios(config);
  };
  const meetingDetails = async (appointmentId) => {
    var urls = `${AGENT_SERVICE}api/v1/appointment/view/${appointmentId}`;
    var config = {
      method: "GET",
      url: urls,
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
    };
    return await axios(config);
  };

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch(
      "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
    )
      .then((res) => res.json())
      .then((body) => {
        console.log("bod=", body);
        setData([...data, ...body.results]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    //loading screen
    meetingDetails(appointmentId, userName).then((res) => {
      // console.log("res. ->", res);
      const result = res;
      setAppointment(result);
      // console.log("description. ->", result.data.data.description);
      let conferenceDetails = {};
      //company Logo
      if (
        result.data &&
        result.data.data &&
        result.data.data.companyConfig &&
        result.data.data.companyConfig.companyLogo
      ) {
        // console.log("LOGO IS PRESENT", result.data.data.companyConfig.companyLogo)
        conferenceDetails.companyLogoURL =
          result.data.data.companyConfig.companyLogo;
      } else {
        conferenceDetails.companyLogoURL = DialogViewImage;
      }
      //agentname
      if (result.data && result.data.data && result.data.data.agent) {
        conferenceDetails.userName = result.data.data.agent;
      }
      //meetingid
      if (result.data && result.data.data && result.data.data.meetingId) {
        conferenceDetails.meetingId = result.data.data.meetingId;
      } else {
        conferenceDetails.meetingId = 1234;
      }
      //meeting summary
      let conferenceInformation = {};
      if (result.data && result.data.data && result.data.data.summary) {
        conferenceInformation.meetingSummary = result.data.data.summary;
      }
      //meeting description
      if (result.data && result.data.data && result.data.data.description) {
        conferenceInformation.meetingDescription = result.data.data.description;
      }
      //participants
      if (result?.data?.data?.attendeesEmailList?.length > 0) {
        setData(result.data.data.attendeesEmailList);
        setAttendeesEmailList(result?.data?.data?.attendeesEmailList);
      }
      setState((prevState) => {
        return {
          ...prevState,
          userName: conferenceDetails.userName,
          meetingId: conferenceDetails.meetingId,
          meetingDetails: {
            meetingDescription: conferenceInformation.meetingDescription,
            meetingSummary: conferenceInformation.meetingSummary,
          },
          companyLogoURL: conferenceDetails.companyLogoURL,
        };
      });
      startMeet(conferenceDetails.meetingId, result, userName);
      // loadMoreData();
    });
  }, []);

  const startMeet = async (meetingId, appointmentDetails, userName) => {
    console.log("START MEETing:", meetingId);
    const loadJitsiScript = () => {
      let resolveLoadJitsiScriptPromise = null;

      const loadJitsiScriptPromise = new Promise((resolve) => {
        resolveLoadJitsiScriptPromise = resolve;
      });

      const script = document.createElement("script");
      script.src = "https://conferencing.dialogview.ai/external_api.js";
      script.async = true;
      script.onload = () => resolveLoadJitsiScriptPromise(true);
      document.body.appendChild(script);

      return loadJitsiScriptPromise;
    };

    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }
    const DOMAIN = "conferencing.dialogview.ai";

    const options = {
      roomName: meetingId,
      // width: "100%",
      // height: "100%",
      configOverwrite: {
        prejoinPageEnabled: false,
        startWithVideoMuted: true,
        startWithAudioMuted: true,
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        disableRemoteMute: true,
        remoteVideoMenu: {
          disableKick: true,
        },
        disableDeepLinking: true,
        desktopSharingFrameRate: {
          min: 30,
          max: 30,
        },
      },
      interfaceConfigOverwrite: {
        // overwrite interface properties
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        MOBILE_APP_PROMO: false,
        TOOLBAR_BUTTONS: [
          "fullscreen",
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          // "embedmeeting",
          "fodeviceselection",
          "hangup",
          // "profile",
          "chat",
          "recording",
          "livestreaming",
          // "etherpad",
          // "sharedvideo",
          // "shareaudio",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          // "invite",
          "feedback",
          // "stats",
          "shortcuts",
          "tileview",
          "select-background",
          "download",
          "help",
          "mute-everyone",
          "mute-video-everyone",
          // "security",
          "whiteboard",
        ],
      },
      parentNode: document.querySelector("#jitsi-iframe"),
      userInfo: {
        // displayName: "Agent_" + new Date().getMilliseconds(), //name from params
        displayName: userName,
      },
      onload: () => {
        updateScheduleStatus(true, appointmentDetails);
        userStatusChange(agentId, "engaged");
        meetingUpdate(true, appointmentDetails);
      },
    };

    if (!jitsiApi) jitsiApi = new window.JitsiMeetExternalAPI(DOMAIN, options);

    // temp not forcing
    // api.executeCommand("resizeFilmStrip", {
    //   width: 100, // The desired filmstrip width
    // });
    jitsiApi.addEventListeners({
      readyToClose: () => handleClose(appointmentDetails),
      //   participantLeft: handleParticipantLeft,
      //   participantJoined: handleParticipantJoined,
      //   videoConferenceJoined: handleVideoConferenceJoined,
      //   videoConferenceLeft: handleVideoConferenceLeft,
      //   audioMuteStatusChanged: handleMuteStatus,
      //   videoMuteStatusChanged: handleVideoStatus,
    });
  };

  const toggleDetailCollapsed = () => {
    setDetailCollapsed((val) => !val);
  };
  return (
    <Layout className="layout" style={{ borderRadius: 8 }}>
      <Header className="header">
        <Image
          className="company-logo"
          preview={false}
          src={state.companyLogoURL ? state.companyLogoURL : DialogViewImage}
        />
        {/* <img src= alt="Company Logo" /> */}
        <div className="meeting-header">
          {state.meetingDetails.meetingSummary}
          {/* Real-Time Transactions: Instant Payments with RTP & FedNow */}
        </div>
      </Header>
      <Layout hasSider>
        <Sider
          width={detailCollapsed ? 80 : 300} // Adjust the width based on the collapse state
          className="siderClass"
          collapsible
          collapsed={detailCollapsed}
          onCollapse={toggleDetailCollapsed}
          trigger={null}
        >
          <div style={{ padding: "10px", textAlign: "right" }}>
            {detailCollapsed ? (
              <RiMenuUnfoldFill
                color="black"
                size={30}
                onClick={toggleDetailCollapsed}
              />
            ) : (
              <RiMenuFoldFill
                color="black"
                size={30}
                onClick={toggleDetailCollapsed}
              />
            )}
          </div>
          <Menu
            mode="inline"
            selectable={false}
            style={{
              marginTop: "5px",
              // height: "auto",
            }}
            defaultOpenKeys={["MeetingDetails", "Participants"]}
          >
            <Menu.SubMenu
              // title="Meeting Details"
              title={
                detailCollapsed ? <MdDataUsage size={20} /> : "Meeting Details"
              }
              key="MeetingDetails"
              inlineCollapsed={true}
            >
              <Menu.Item key="Meeting_Id">
                <strong>Meeting Id</strong> - {state.meetingId}
              </Menu.Item>

              <Menu.Item key="Meeting_Description">
                <strong>Meeting Description</strong> -{" "}
                {state.meetingDetails.meetingDescription}
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              title={
                detailCollapsed ? <BsPeopleFill size={20} /> : "Participants"
              }
              key="Participants"
            >
              {attendeesEmailList?.map((item,idx) => (
                <Menu.Item key={`${item}_${idx}`}>{item}</Menu.Item>
              ))}
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <Content className="contentStyle">
          {!callClosed && (
            <div
              id="jitsi-iframe"
              style={{
                height: "100%",
                width: "100%",
                transition: "all 0.2s ease-in-out",
              }}
            ></div>
          )}
          {callClosed && (
            <div style={{ margin: "10%" }}>
              <div
                id="thankyou"
                style={{
                  fontFamily: "monospace",
                  fontSize: "24px",
                  color: "#3498db",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  textTransform: "uppercase",
                }}
              >
                {" "}
                Wow! Big congrats to you for putting together an awesome
                conference! Thanks a bunch!
              </div>
              <img src={ConferenceCall} alt="Animated GIF" />
            </div>
          )}
        </Content>
      </Layout>
      {/* <Footer style={footerStyle}>Footer</Footer> */}
    </Layout>
  );
};

export default AgentCallWindow;
