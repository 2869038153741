import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import JitsiConference from "./Jitsi-Conference";
import AgentCallWindow from "./Agent-Jitsi-Conference";

function App() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  if (params.id) {
    return <AgentCallWindow />;
  } else {
    return <JitsiConference />;
  }

  // ;
}

export default App;
