import React, { Component } from "react";
import JitsiWindow from "../Jitsi-Window";

export class Conference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingId: this.props.meetingId || "Room",
      userName: this.props.userName || "Test User",
      companyLogoURL: this.props.companyLogoURL,
      closeModal: this.props.closeModal,
    };
  }
  render() {
    return (
      <div className="conferencePage-container">
        <JitsiWindow
          meetingId={this.state.meetingId}
          userName={this.state.userName}
          companyLogoURL={this.state.companyLogoURL}
          closeModal={this.state.closeModal}
          meetingDetails={this.props.meetingDetails}
        />
        <footer className="footer">
          Powered By&nbsp;
          <span>
            <a href="https://dialogview.com" target="_blank">
              DialogView
            </a>
          </span>
        </footer>
      </div>
    );
  }
}

export default Conference;
